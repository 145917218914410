const IconCrossMenu = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18.275" viewBox="0 0 24 18.275">
      <g id="cross-menu" transform="translate(0 -0.522)">
        <path id="Tracé_19" data-name="Tracé 19" d="M.579,2.262l22.171-.57c.339-.009.624-.432.638-.953s-.255-.93-.59-.922L.627.387C.288.4,0,.819-.011,1.34S.244,2.271.579,2.262Z" transform="translate(4.47 0.66) rotate(45)" fill="#fff" />
        <path id="Tracé_20" data-name="Tracé 20" d="M.629,293.918H23.371c.348,0,.629-.406.629-.914s-.286-.914-.629-.914H.629c-.348,0-.629.406-.629.914S.286,293.918.629,293.918Z" transform="translate(0 -283.465)" fill="#fff" opacity="0" />
        <path id="Tracé_21" data-name="Tracé 21" d="M.645,1.88l22.171.57c.339.009.6-.4.59-.922s-.3-.945-.638-.953L.6,0C.258,0-.006.405.008.927S.31,1.872.645,1.88Z" transform="translate(2.854 17.07) rotate(-45)" fill="#fff" />
      </g>
    </svg>
  );
};

export default IconCrossMenu;
