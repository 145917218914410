const IconRedCross = () => {
  return (
    <svg id="red-cross" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <rect id="Rectangle_1390" data-name="Rectangle 1390" width="32" height="32" fill="#fff" opacity="0" />
      <g id="Groupe_419" data-name="Groupe 419" transform="translate(-625 102)">
        <path id="Union_8" data-name="Union 8" d="M2577.24-3409.13l-3.241-3.241-3.242,3.241a.441.441,0,0,1-.313.13.443.443,0,0,1-.315-.13.444.444,0,0,1,0-.629l3.241-3.241-3.241-3.242a.442.442,0,0,1,0-.628.442.442,0,0,1,.628,0l3.242,3.241,3.241-3.241a.445.445,0,0,1,.63,0,.446.446,0,0,1,0,.628l-3.242,3.242,3.242,3.241a.448.448,0,0,1,0,.629.446.446,0,0,1-.315.13A.45.45,0,0,1,2577.24-3409.13Z" transform="translate(-1933 3327)" fill="#e75012" />
      </g>
    </svg>
  );
};

export default IconRedCross;
