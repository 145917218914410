const IconBlackCross = () => {
  return (
    <svg id="black-cross" xmlns="http://www.w3.org/2000/svg" width="8.001" height="7.999" viewBox="0 0 8.001 7.999">
      <g id="Groupe_419" data-name="Groupe 419" transform="translate(0.001)">
        <path id="Union_10" data-name="Union 10" d="M1734.155-3319.145,1731-3322.3l-3.154,3.155a.492.492,0,0,1-.351.144.492.492,0,0,1-.351-.144.5.5,0,0,1,0-.7L1730.3-3323l-3.155-3.155a.494.494,0,0,1,0-.7.5.5,0,0,1,.7,0L1731-3323.7l3.155-3.154a.494.494,0,0,1,.7,0,.494.494,0,0,1,0,.7L1731.7-3323l3.154,3.154a.5.5,0,0,1,0,.7.491.491,0,0,1-.349.144A.492.492,0,0,1,1734.155-3319.145Z" transform="translate(-1727 3327)" />
      </g>
    </svg>
  );
};

export default IconBlackCross;
