import { PupsAccount } from '../models';

export interface RoleInterface {
  GROUPE: number;
  POINTS_DE_COLLECTS: number;
}

export const roles = {
  GROUPE: 1,
  POINTS_DE_COLLECTS: 2,
};

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
export function hasPermission(
  role: number | undefined,
  profile: null | PupsAccount
) {
  if (role) {
    if (profile?.pups_account_type_id === role) {
      return true;
    }
    return false;
  }
  return true;
}
