const IconModifier = () => {
  return (
    <svg id="modifier" xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
      <path id="Tracé_4239" data-name="Tracé 4239" d="M43.813,18.816a.65.65,0,0,1-.62-.844l1.048-3.346a.65.65,0,0,1,.161-.265l9.82-9.82a.65.65,0,0,1,.919,0l2.3,2.3a.65.65,0,0,1,0,.919l-9.82,9.82a.65.65,0,0,1-.265.161l-3.346,1.048A.65.65,0,0,1,43.813,18.816Zm1.62-3.649L44.8,17.174l2.007-.629L56.059,7.3,54.68,5.92Z" transform="translate(-38.628 -4.35)" fill="#95c515" />
      <path id="Tracé_4240" data-name="Tracé 4240" d="M26.67,38.631H10.815V23.173h10.24a.634.634,0,1,1,0,1.267h-8.94V37.364H25.37V29.982a.65.65,0,0,1,1.3,0Z" transform="translate(-10.815 -20.631)" fill="#95c515" />
    </svg>
  );
};

export default IconModifier;
