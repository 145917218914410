// import request from './request';
import axios from 'axios';
// import { apigClientFactory } from 'aws-api-gateway-client';
// import { defaultProvider } from "@aws-sdk/credential-provider-node";
// import { SignatureV4 } from '@aws-sdk/signature-v4';
// import { HttpRequest } from '@aws-sdk/protocol-http';
// import { Sha256 } from '@aws-crypto/sha256-js';
// var apigClientFactory = require('aws-api-gateway-client').default;

// const accessKeyId = sessionStorage.getItem('access_key_id');
// const secretAccessKey = sessionStorage.getItem('secret_access_key');
// const sessionToken = sessionStorage.getItem('session_token');
// const hostname = "ft5l8owrdl.execute-api.ap-southeast-1.amazonaws.com";

// async function signRequest(
//   headers: Record<string, string>,
//   method: string,
//   path: string,
//   body?: unknown
// ) {
//   const request = new HttpRequest({
//     body: body ? JSON.stringify(body) : undefined,
//     headers,
//     // hostname: hostname,
//     method: method.toUpperCase(),
//     path,
//     protocol: 'https', 
//     // url: 'https://ft5l8owrdl.execute-api.ap-southeast-1.amazonaws.co/dev/orders',
//   });

//   const signer = new SignatureV4({
//     credentials: {
//       accessKeyId: accessKeyId ? accessKeyId : '',
//       secretAccessKey: secretAccessKey ? secretAccessKey : '',
//       sessionToken: sessionToken ? sessionToken : ''
//     },
//     // credentials: defaultProvider(),
//     service: "execute-api",
//     region: "ap-southeast-1",
//     sha256: Sha256,
//   });
//   const signedRequest = await signer.sign(request);
//   return signedRequest;
// }

const orderDemoService = {
  getOrders: () => {

    // const headers: Record<string, string> = {
    //   // host: hostname,
    //   Accept: 'application/json',
    //   'Content-Type': 'application/json',
    // };
    // const path = "/dev/orders";

    // return signRequest(headers, "GET", path).then((req) => {
    //   console.log('req.headers')
    //   console.log(req.headers)
    //   return axios.get(`https://${hostname}${path}`, {
    //     headers: req.headers,
    //   })
    //   .then((response) => { console.log('response++'); console.log(response); return Promise.resolve(response.data);})
    //   .catch((err) => {
    //     console.log('error')
    //     console.log(err)
    //   });
    // });
    //=========
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    };
    return axios
          .get(`${process.env.REACT_APP_BASE_API_ORDER}/orders`, config)
          .then((response) => {return Promise.resolve(response.data);})
          .catch((err) => {
            console.log('error')
          });
  },
  findOrder: (id: number | string) => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    };
    return axios
          .get(`${process.env.REACT_APP_BASE_API_ORDER}/orders/${id}`, config)
          .then((response) => {return Promise.resolve(response.data);})
          .catch((err) => {
            console.log('error')
          });
  },
  updateOrder: (
    orderId: string | number,
    data: any
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    };
    return axios
          .put(`${process.env.REACT_APP_BASE_API_ORDER}/orders/${orderId}`, data, config)
          .then((response) => {return Promise.resolve(response.data);})
          .catch((err) => {
            console.log('error')
          });
  }
};

export default orderDemoService;
