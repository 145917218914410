const IconArrowLeft = () => {
  return (
    <svg id="left-arrow" xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22">
      <g id="right-arrow" transform="translate(12 22) rotate(180)">
        <path id="Tracé_24" data-name="Tracé 24" d="M1.353,21.772A.781.781,0,0,1,.792,22a.781.781,0,0,1-.56-.228.768.768,0,0,1,0-1.1L10.087,11,.232,1.328a.768.768,0,0,1,0-1.1.8.8,0,0,1,1.121,0L11.768,10.45a.768.768,0,0,1,0,1.1Z" fill="#777" />
      </g>
    </svg>
  );
};

export default IconArrowLeft;
