import { useState, useEffect, useMemo } from 'react';
import { Menu, Popover, Button, Row, Col, Modal } from 'antd';
import { Link, useLocation } from "react-router-dom";

import { useAppDispatch } from '../../../store';
import useAuthContext from '../../../store/auth-context';
import Logo from '../../../assets/images/logo-ecodrop.png';
import { commandesRealiseesFiltrerChange } from '../../../store/slices/commandes-realisees-iltrer.slices';
import {
  IconBurger,
  IconCrossMenu,
  IconMail,
  IconPhone,
} from '../../Icons';


const Header = ({ onVisibleDrawerChange }: { onVisibleDrawerChange: Function }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visiblePhone, setVisiblePhone] = useState(false);
  const { profile: account, onSignOut } = useAuthContext();

  const signOut = () => {
    dispatch(
      commandesRealiseesFiltrerChange({
        date_start: '',
        date_end: '',
        pups_order_type: [],
      })
    );
    onSignOut();
  };
  useEffect(() => {
    onVisibleDrawerChange(visibleDrawer);
  }, [onVisibleDrawerChange, visibleDrawer]);

  const fullName = useMemo(
    () => [account?.first_name, account?.last_name].filter((i) => i).join(' '),
    [account]
  );

  const address = useMemo(
    () =>
      [
        account?.pups_location_account?.pups_location?.address,
        `${account?.pups_location_account?.pups_location?.zip_code} ${account?.pups_location_account?.pups_location?.city}`,
      ]
        .filter((i) => i)
        .join(', '),
    [account]
  );

  const menuVertical = (
    <div className='header-popover'>
      <div className='profile'>
        <h3 className={`profile__name ${account?.is_super_account === 0 ? 'color-primary' : 'color-secondary'}`}>{fullName}</h3>
        {/* <h3 className={`profile__name ${account?.pups_account_type_id === 1 ? 'color-primary' : 'color-secondary'}`}>{fullName}</h3> */}
        {account?.pups_account_type_id === 1 && <p className='profile__title'>{'Compte utilisateur'}</p>}
        {account?.pups_account_type_id === 2 && <p className='profile__title'>{address}</p>}
      </div>
      <Menu
        selectedKeys={[location.pathname]}
        mode='inline'
        className='header-navbar'>
        {account?.pups_account_type_id === 1 ?
          <Menu.Item key='/'>
            <Link to='/'>Choisir mon agence</Link>
          </Menu.Item>
          :
          <>
            <Menu.Item key='/collecter-mes-dechets'>
              <Link to='/collecter-mes-dechets'>
                Collecter mes déchets
              </Link>
            </Menu.Item>

            <Menu.Item key='/commandes-en-cours'>
              <Link to='/commandes-en-cours'>Suivre mes commandes</Link>
            </Menu.Item>
          </>
        }
        <Menu.Item key='contact'>
          <div className='flex-justify-between align-center color-grey-weight'>
            {'Contacter Ecodrop'}&nbsp; &gt;

            <Row justify="space-between" align="middle" >
              <Col>
                <Button type="text" className="header-navbar__icon desktop-hidden" href='tel:0788034945' icon={<IconPhone />} />
                <Button type="text" className="header-navbar__icon mobile-hidden" onClick={() => setVisiblePhone(true)} icon={<IconPhone />} />
                <Modal centered footer={null} closable={false} visible={visiblePhone} >
                  <div className="flex-align-center py-5">
                    <p className="text-h2 color-primary text-spacing-4 py-5">0788034945</p>
                    <Button type="primary" className='px-16 mt-4' onClick={() => setVisiblePhone(false)}> OK </Button>
                  </div>
                </Modal>
              </Col>
              <Col>
                <Button type="text" className="header-navbar__icon" href='mailto:collectepointp@ecodrop.net' icon={<IconMail />} />
              </Col>
            </Row>
          </div>
        </Menu.Item>
        <Menu.Item key='/logout' onClick={signOut}>
          <div className="ant-btn-text ant-btn-dangerous">
            {'Se déconnecter'}
          </div>
        </Menu.Item>
      </Menu>

    </div>
  );

  return (
    <div className='header__wrapper'>
      <header className='header'>
        <Link to='/'>
          <div className='d-flex'>
            <img src={Logo} alt='Service déchèterie en agence' width={90} height={40} />
          </div>
        </Link>
        <Popover
          trigger="click"
          placement='bottomRight'
          visible={visibleDrawer}
          content={menuVertical}
          onVisibleChange={setVisibleDrawer}
          id="header-popover"
        >
          <a className='header__menu-button'>{visibleDrawer ? <IconCrossMenu /> : <IconBurger />}</a>
        </Popover>
      </header>
    </div>
  );
};

export default Header;
