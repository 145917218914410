const IconCollectesDI = () => {
  return (
    <svg id="Collectes_DND" data-name="Collectes DND" xmlns="http://www.w3.org/2000/svg" width="60" height="80" viewBox="0 0 60 80">
      <rect id="Rectangle_1430" data-name="Rectangle 1430" width="60" height="80" fill="#fff" opacity="0.004" />
      <g id="Groupe_1414" data-name="Groupe 1414" transform="translate(5 3)">
        <g id="Groupe_1404" data-name="Groupe 1404" transform="translate(0 0)">
          <path id="Tracé_4333" data-name="Tracé 4333" d="M0,0H7.827V42.469H0Z" transform="matrix(0.083, -0.997, 0.997, 0.083, 8.198, 61.175)" fill="#95c515" />
          <path id="Tracé_4283" data-name="Tracé 4283" d="M88.354,323.38l-43.337-3.624.737-8.815,43.338,3.625Zm-42.237-4.555,41.306,3.455.567-6.784-41.306-3.455Z" transform="translate(-37.369 -258.116)" fill="#4a4b4f" />
          <line id="Ligne_116" data-name="Ligne 116" x2="17.767" y2="1.539" transform="translate(9.907 58.144)" fill="#fff" />
          <rect id="Rectangle_1419" data-name="Rectangle 1419" width="1.019" height="17.834" transform="translate(9.863 58.652) rotate(-85.049)" fill="#4a4b4f" />
          <line id="Ligne_117" data-name="Ligne 117" x2="13.086" y2="1.063" transform="translate(30.137 58.988)" fill="#fff" />
          <rect id="Rectangle_1420" data-name="Rectangle 1420" width="1.019" height="13.129" transform="translate(30.096 59.495) rotate(-85.356)" fill="#4a4b4f" />
          <line id="Ligne_118" data-name="Ligne 118" x2="9.568" y2="0.769" transform="translate(37.393 57.781)" fill="#fff" />
          <rect id="Rectangle_1421" data-name="Rectangle 1421" width="1.019" height="9.599" transform="matrix(0.08, -0.997, 0.997, 0.08, 37.352, 58.29)" fill="#4a4b4f" />
          <path id="Tracé_4284" data-name="Tracé 4284" d="M143.838,243.191l-27.267-28.5,5.632-5.435,27.267,28.5Z" transform="translate(-96.767 -173.707)" fill="#fff" />
          <g id="Groupe_1403" data-name="Groupe 1403">
            <g id="Groupe_1410" data-name="Groupe 1410">
              <path id="Tracé_4285" data-name="Tracé 4285" d="M141.348,241.21h-.01a.509.509,0,0,1-.358-.157l-27.267-28.5a.509.509,0,0,1,.014-.719l5.632-5.434a.509.509,0,0,1,.722.014l27.267,28.5a.51.51,0,0,1-.014.719l-5.632,5.435a.51.51,0,0,1-.354.143M114.8,212.215l26.563,27.763,4.9-4.727L119.7,207.488Z" transform="translate(-94.277 -171.216)" fill="#4a4b4f" />
              <line id="Ligne_119" data-name="Ligne 119" x2="9.139" y2="9.426" transform="translate(23.357 40.309)" fill="#fff" />
              <rect id="Rectangle_1422" data-name="Rectangle 1422" width="1.019" height="13.129" transform="matrix(0.718, -0.696, 0.696, 0.718, 22.991, 40.664)" fill="#4a4b4f" />
              <line id="Ligne_120" data-name="Ligne 120" x2="9.139" y2="9.426" transform="translate(36.106 52.948)" fill="#fff" />
              <rect id="Rectangle_1423" data-name="Rectangle 1423" width="1.019" height="13.129" transform="translate(35.74 53.303) rotate(-44.112)" fill="#4a4b4f" />
              <line id="Ligne_121" data-name="Ligne 121" x2="6.687" y2="6.886" transform="translate(42.357 56.825)" fill="#fff" />
              <rect id="Rectangle_1424" data-name="Rectangle 1424" width="1.019" height="9.599" transform="translate(41.992 57.18) rotate(-44.16)" fill="#4a4b4f" />
              <line id="Ligne_122" data-name="Ligne 122" x2="6.798" y2="7.085" transform="translate(29.276 43.222)" fill="#fff" />
              <rect id="Rectangle_1425" data-name="Rectangle 1425" width="1.019" height="9.819" transform="translate(28.908 43.575) rotate(-43.814)" fill="#4a4b4f" />
              <path id="Tracé_4286" data-name="Tracé 4286" d="M22.351,292.774l9.933-2.986,10.3,3.047-10.055,4.022Z" transform="translate(-18.554 -240.557)" fill="#fff" />
              <path id="Tracé_4287" data-name="Tracé 4287" d="M30.037,294.876a.51.51,0,0,1-.19-.037l-10.177-4.083a.51.51,0,0,1,.043-.961l9.933-2.986a.511.511,0,0,1,.291,0l10.3,3.047a.51.51,0,0,1,.045.962L30.226,294.84a.507.507,0,0,1-.189.037M21.4,290.353l8.636,3.465,8.5-3.4-8.745-2.587Z" transform="translate(-16.063 -238.066)" fill="#4a4b4f" />
              <path id="Tracé_4288" data-name="Tracé 4288" d="M82.253,311.745V324.7l10.016-4.344V307.723Z" transform="translate(-68.279 -255.445)" fill="#fff" />
              <path id="Tracé_4289" data-name="Tracé 4289" d="M79.763,322.72a.51.51,0,0,1-.51-.51V309.255a.51.51,0,0,1,.32-.473l10.016-4.022a.51.51,0,0,1,.7.473v12.632a.509.509,0,0,1-.307.468l-10.016,4.344a.506.506,0,0,1-.2.042m.51-13.12v11.834l9-3.9V305.987Z" transform="translate(-65.789 -252.955)" fill="#4a4b4f" />
              <path id="Tracé_4290" data-name="Tracé 4290" d="M32.518,311.448l-10.177-4.083,0,12.515L32.518,324.4s-.147-12.955,0-12.955" transform="translate(-18.545 -255.148)" fill="#fff" />
              <path id="Tracé_4291" data-name="Tracé 4291" d="M30.028,322.422a.508.508,0,0,1-.207-.044l-10.178-4.523a.51.51,0,0,1-.3-.466l0-12.515a.51.51,0,0,1,.7-.473l10.177,4.083a.51.51,0,0,1,.3.627c-.08.781-.03,8.029.024,12.795a.51.51,0,0,1-.51.515m-9.669-5.363,9.15,4.066c-.012-1.153-.033-3.4-.046-5.632-.022-3.829-.021-5.465.024-6.2l-9.126-3.662Zm9.67-7.591h0Zm.3-.1a.509.509,0,0,1-.3.1.5.5,0,0,0,.3-.1" transform="translate(-16.054 -252.658)" fill="#4a4b4f" />
              <path id="Tracé_4292" data-name="Tracé 4292" d="M82.253,311.745l3.275,4.487,9.721-4.519-2.942-3.989Z" transform="translate(-68.279 -255.445)" fill="#95c515" />
              <path id="Tracé_4293" data-name="Tracé 4293" d="M81.462,313.844l-3.909-5.355,11.03-4.412,3.546,4.808Zm-2.312-4.9,2.642,3.619,8.776-4.08-2.338-3.171Z" transform="translate(-64.378 -252.418)" fill="#4a4b4f" />
              <path id="Tracé_4294" data-name="Tracé 4294" d="M18.824,311.448l-3.108,4.412-9.873-4.444,2.8-4.05Z" transform="translate(-4.85 -255.148)" fill="#95c515" />
              <path id="Tracé_4295" data-name="Tracé 4295" d="M12.139,313.445,1.324,308.576l3.385-4.888,11.152,4.474Zm-9.28-5.3,8.931,4.02,2.495-3.541-9.2-3.691Z" transform="translate(-1.099 -252.095)" fill="#4a4b4f" />
              <path id="Tracé_4296" data-name="Tracé 4296" d="M7.743,271.685l-2.99-4.446,9.43-2.856,3.492,4.316Z" transform="translate(-3.946 -219.467)" fill="#95c515" />
              <path id="Tracé_4297" data-name="Tracé 4297" d="M3.584,269.412,0,264.082l10.418-3.156L14.6,266.1Zm-1.968-4.754,2.395,3.562,8.846-2.659-2.8-3.459Z" transform="translate(0 -216.598)" fill="#4a4b4f" />
              <path id="Tracé_4298" data-name="Tracé 4298" d="M80.818,268.316l10.3,3.047,3.112-4.585-9.821-2.856Z" transform="translate(-67.088 -219.085)" fill="#95c515" />
              <path id="Tracé_4299" data-name="Tracé 4299" d="M87.032,269.106l-11.391-3.37,4.288-5.249,10.818,3.146ZM77.4,265.194l9.206,2.724,2.509-3.7-8.824-2.566Z" transform="translate(-62.791 -216.234)" fill="#4a4b4f" />
              <path id="Tracé_4300" data-name="Tracé 4300" d="M177.96,386.936l19.652-.323c.947-.016,1.7-1.194,1.672-2.631s-.81-2.591-1.757-2.575l-19.652.323" transform="translate(-147.655 -316.61)" fill="#95c515" />
              <path id="Tracé_4301" data-name="Tracé 4301" d="M177.928,384.954l-.017-1.019,19.652-.323c.71-.012,1.186-1.123,1.17-2.113s-.524-2.074-1.228-2.074h-.011l-19.652.323-.017-1.019,19.652-.323h.028c1.234,0,2.219,1.344,2.247,3.076s-.926,3.129-2.173,3.15Z" transform="translate(-147.616 -314.119)" fill="#4a4b4f" />
              <path id="Tracé_4302" data-name="Tracé 4302" d="M171.462,385.882c.024,1.438-.725,2.616-1.672,2.631s-1.733-1.137-1.757-2.575.725-2.616,1.671-2.631,1.734,1.137,1.757,2.575" transform="translate(-139.486 -318.188)" fill="#fff" />
              <path id="Tracé_4303" data-name="Tracé 4303" d="M167.282,386.533a2.009,2.009,0,0,1-1.627-.965,4.132,4.132,0,0,1-.07-4.242,2,2,0,0,1,1.622-1.019h.027a2.009,2.009,0,0,1,1.627.965,3.891,3.891,0,0,1,.621,2.111c.029,1.746-.926,3.129-2.173,3.15h-.027m-.048-5.207h-.011a1.042,1.042,0,0,0-.778.546,3.122,3.122,0,0,0,.051,3.121,1.037,1.037,0,0,0,.8.52c.71-.012,1.187-1.123,1.17-2.113a2.907,2.907,0,0,0-.444-1.553,1.051,1.051,0,0,0-.785-.52" transform="translate(-136.996 -315.698)" fill="#4a4b4f" />
              <path id="Tracé_4304" data-name="Tracé 4304" d="M44.752,116.547h7.709S39.78,122.413,47.1,135.764c0,0-9.329-8.491-2.346-19.217" transform="translate(-34.996 -96.747)" fill="#e75012" />
              <path id="Tracé_4306" data-name="Tracé 4306" d="M198.892,116.547h-7.709s12.681,5.866,5.363,19.217c0,0,9.329-8.491,2.346-19.217" transform="translate(-158.703 -96.747)" fill="#e75012" />
              <path id="Tracé_4308" data-name="Tracé 4308" d="M80.808,63.426l-14,7.2H94.182Z" transform="translate(-55.461 -52.651)" fill="#e75012" />
              <path id="Tracé_4310" data-name="Tracé 4310" d="M140.877,5.836A1.635,1.635,0,0,1,139.243,4.2V1.634a1.634,1.634,0,0,1,3.267,0V4.2a1.635,1.635,0,0,1-1.634,1.634m0-4.817a.615.615,0,0,0-.614.614V4.2a.614.614,0,1,0,1.229,0V1.634a.615.615,0,0,0-.614-.614" transform="translate(-115.587)" fill="#e75012" />
              <path id="Tracé_4311" data-name="Tracé 4311" d="M140.877,34.587a1.635,1.635,0,0,1-1.634-1.634V30.385a1.634,1.634,0,0,1,3.267,0v2.569a1.635,1.635,0,0,1-1.634,1.634m0-4.817a.615.615,0,0,0-.614.614v2.569a.614.614,0,0,0,1.229,0V30.385a.615.615,0,0,0-.614-.614" transform="translate(-115.587 -23.867)" fill="#e75012" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
};

export default IconCollectesDI;
