const IconCollectesDI = () => {
  return (
    <svg id="Collectes_DI" data-name="Collectes DI" xmlns="http://www.w3.org/2000/svg" width="60" height="80" viewBox="0 0 60 80">
      <rect id="Rectangle_1432" data-name="Rectangle 1432" width="60" height="80" fill="#fff" opacity="0.004" />
      <g id="Groupe_1413" data-name="Groupe 1413" transform="translate(8 3)">
        <g id="Groupe_1411" data-name="Groupe 1411" transform="translate(0 0)">
          <path id="Tracé_4241" data-name="Tracé 4241" d="M29.443,116.546h7.709s-12.681,5.866-5.363,19.217c0,0-9.329-8.491-2.346-19.217" transform="translate(-22.288 -96.746)" fill="#e75012" />
          <path id="Tracé_4243" data-name="Tracé 4243" d="M183.582,116.546h-7.709s12.681,5.866,5.363,19.217c0,0,9.329-8.491,2.346-19.217" transform="translate(-145.994 -96.746)" fill="#e75012" />
          <path id="Tracé_4245" data-name="Tracé 4245" d="M65.5,63.426l-14,7.2H78.872Z" transform="translate(-42.752 -52.651)" fill="#e75012" />
          <path id="Tracé_4247" data-name="Tracé 4247" d="M125.568,5.836A1.635,1.635,0,0,1,123.934,4.2V1.634a1.634,1.634,0,0,1,3.267,0V4.2a1.635,1.635,0,0,1-1.634,1.634m0-4.817a.615.615,0,0,0-.614.614V4.2a.614.614,0,1,0,1.229,0V1.634a.615.615,0,0,0-.614-.614" transform="translate(-102.879)" fill="#e75012" />
          <path id="Tracé_4248" data-name="Tracé 4248" d="M125.568,34.587a1.635,1.635,0,0,1-1.634-1.634V30.385a1.634,1.634,0,1,1,3.267,0v2.569a1.635,1.635,0,0,1-1.634,1.634m0-4.817a.615.615,0,0,0-.614.614v2.569a.614.614,0,0,0,1.229,0V30.385a.615.615,0,0,0-.614-.614" transform="translate(-102.879 -23.867)" fill="#e75012" />
          <path id="Tracé_4249" data-name="Tracé 4249" d="M23.453,285.3l-4.2-3.254.271-1.762,2.938-2.35,5.739-.407,2.079,1.672-.994,4.248Z" transform="translate(-15.98 -230.381)" fill="#fff" />
          <path id="Tracé_4250" data-name="Tracé 4250" d="M20.962,283.323a.509.509,0,0,1-.312-.107l-4.2-3.254a.509.509,0,0,1-.192-.48l.271-1.762a.51.51,0,0,1,.186-.321l2.937-2.35a.509.509,0,0,1,.282-.11l5.739-.407a.511.511,0,0,1,.355.111l2.079,1.672a.51.51,0,0,1,.177.513l-.994,4.248a.51.51,0,0,1-.342.37l-5.83,1.853a.512.512,0,0,1-.154.024m-3.653-3.982,3.754,2.906,5.3-1.684.855-3.654-1.673-1.346-5.381.381L17.5,278.071Z" transform="translate(-13.489 -227.891)" fill="#4a4b4f" />
          <path id="Tracé_4251" data-name="Tracé 4251" d="M135.254,256.873l-4.2-3.254.271-1.763,2.938-2.35L140,249.1l2.079,1.672-.994,4.248Z" transform="translate(-108.787 -206.781)" fill="#fff" />
          <path id="Tracé_4252" data-name="Tracé 4252" d="M132.764,254.892a.509.509,0,0,1-.312-.107l-4.2-3.254a.51.51,0,0,1-.192-.481l.271-1.762a.51.51,0,0,1,.185-.32l2.938-2.35a.509.509,0,0,1,.282-.11l5.739-.407a.51.51,0,0,1,.355.111l2.079,1.672a.51.51,0,0,1,.177.513l-.994,4.248a.51.51,0,0,1-.342.369l-5.83,1.853a.512.512,0,0,1-.154.024m-3.653-3.983,3.754,2.906,5.3-1.684.855-3.654-1.673-1.346-5.381.381-2.658,2.127Z" transform="translate(-106.298 -204.291)" fill="#4a4b4f" />
          <path id="Tracé_4253" data-name="Tracé 4253" d="M77.483,252.4l2.757-2.711v-3.118l-3.028-3.344L71.88,242.5l-.587,2.169,3.209,8.044Z" transform="translate(-59.181 -201.302)" fill="#fff" />
          <path id="Tracé_4254" data-name="Tracé 4254" d="M72.011,250.732a.51.51,0,0,1-.473-.321l-3.208-8.044a.509.509,0,0,1-.019-.322l.587-2.169a.51.51,0,0,1,.56-.372l5.333.723a.51.51,0,0,1,.309.163l3.028,3.344a.51.51,0,0,1,.132.342v3.118a.51.51,0,0,1-.152.363l-2.757,2.712a.509.509,0,0,1-.3.143l-2.983.316-.054,0m-2.672-8.585,3,7.528,2.422-.257,2.478-2.437v-2.708l-2.771-3.061-4.7-.638Z" transform="translate(-56.69 -198.812)" fill="#4a4b4f" />
          <path id="Tracé_4255" data-name="Tracé 4255" d="M172.438,314.175l1.9-5.468-2.44-1.762-3.389,1.039,1.509,2.992-1.916,3.38Z" transform="translate(-139.541 -254.798)" fill="#fff" />
          <path id="Tracé_4256" data-name="Tracé 4256" d="M165.609,312.375a.51.51,0,0,1-.443-.761l1.781-3.143-1.386-2.749a.509.509,0,0,1,.306-.717l3.389-1.04a.509.509,0,0,1,.448.074l2.44,1.762a.51.51,0,0,1,.183.58l-1.9,5.468a.509.509,0,0,1-.46.342l-4.338.181h-.021m1.134-6.572,1.237,2.453a.51.51,0,0,1-.012.481l-1.463,2.581,3.074-.128,1.66-4.782-1.927-1.392Z" transform="translate(-137.051 -252.308)" fill="#4a4b4f" />
          <path id="Tracé_4257" data-name="Tracé 4257" d="M51.419,343.731l1.9-5.468-2.44-1.762-3.389,1.039-1.627,2.983,1.22,3.389Z" transform="translate(-38.069 -279.333)" fill="#fff" />
          <path id="Tracé_4258" data-name="Tracé 4258" d="M44.59,341.931a.51.51,0,0,1-.479-.337l-1.22-3.389a.509.509,0,0,1,.032-.417l1.627-2.983a.509.509,0,0,1,.3-.243l3.389-1.039a.509.509,0,0,1,.448.074l2.44,1.763a.51.51,0,0,1,.183.58l-1.9,5.468a.51.51,0,0,1-.46.342l-4.338.181H44.59m-.663-3.856,1.016,2.822,3.618-.151,1.66-4.782-1.927-1.392-2.948.9Z" transform="translate(-35.579 -276.843)" fill="#4a4b4f" />
          <path id="Tracé_4259" data-name="Tracé 4259" d="M142,278.747l-4.2-3.254.271-1.762,2.938-2.35,5.739-.407,2.079,1.672-.994,4.248Z" transform="translate(-114.384 -224.939)" fill="#fff" />
          <path id="Tracé_4335" data-name="Tracé 4335" d="M142,278.747l-4.2-3.254.271-1.762,2.938-2.35,5.739-.407,2.079,1.672-.994,4.248Z" transform="translate(-116.245 -217.954)" fill="#fff" />
          <path id="Tracé_4260" data-name="Tracé 4260" d="M139.505,276.766a.509.509,0,0,1-.312-.107l-4.2-3.254a.509.509,0,0,1-.192-.48l.271-1.762a.51.51,0,0,1,.185-.321l2.938-2.35a.509.509,0,0,1,.282-.11l5.739-.407a.51.51,0,0,1,.355.111l2.079,1.672a.51.51,0,0,1,.177.513l-.994,4.248a.51.51,0,0,1-.342.37l-5.83,1.853a.512.512,0,0,1-.154.024m-3.653-3.983,3.754,2.906,5.3-1.684.855-3.654-1.673-1.346-5.381.381-2.658,2.127Z" transform="translate(-111.893 -222.449)" fill="#4a4b4f" />
          <path id="Tracé_4261" data-name="Tracé 4261" d="M194.082,304.808l2.757-2.711v-3.118l-3.028-3.344-5.333-.723-.587,2.169,3.209,8.044Z" transform="translate(-155.971 -244.809)" fill="#95c515" />
          <path id="Tracé_4262" data-name="Tracé 4262" d="M188.149,302.91l-3.415-8.561.749-2.765,5.96.808,3.285,3.628v3.528l-3.036,2.986Zm-2.342-8.622,3,7.528,2.422-.257,2.478-2.437v-2.708l-2.771-3.061-4.7-.638Z" transform="translate(-153.35 -242.048)" fill="#4a4b4f" />
          <path id="Tracé_4263" data-name="Tracé 4263" d="M38.164,298.348l1.808-3.118-.316-5.468-1.175-.949L35.5,290.169l-1.9,2.621-3.886,1.717-.633,3.751,2.124,1.536Z" transform="translate(-24.141 -239.747)" fill="#fff" />
          <path id="Tracé_4264" data-name="Tracé 4264" d="M28.715,297.813a.509.509,0,0,1-.3-.1l-2.124-1.536a.51.51,0,0,1-.2-.5l.633-3.751a.509.509,0,0,1,.3-.381l3.76-1.661,1.817-2.51a.511.511,0,0,1,.2-.165l2.983-1.356a.509.509,0,0,1,.531.067l1.175.949a.51.51,0,0,1,.189.367l.316,5.468a.509.509,0,0,1-.068.285l-1.808,3.118a.509.509,0,0,1-.337.243L28.818,297.8a.513.513,0,0,1-.1.011m-1.569-2.274,1.685,1.219,6.516-1.354,1.617-2.789-.295-5.09-.755-.609-2.573,1.17L31.523,290.6a.508.508,0,0,1-.207.167l-3.635,1.606Z" transform="translate(-21.65 -237.256)" fill="#4a4b4f" />
          <path id="Tracé_4265" data-name="Tracé 4265" d="M3.475,355.335l1.078-5.12-.269-3.234,3.908-.539,4.446,1.617,1.752,5.659-7.141,2.964Z" transform="translate(-2.885 -287.586)" fill="#95c515" />
          <path id="Tracé_4266" data-name="Tracé 4266" d="M4.377,354.678,0,353.115l1.154-5.483-.3-3.641,4.511-.622,4.8,1.747,1.964,6.344Zm-3.2-2.224,3.169,1.132,6.531-2.711L9.341,345.9l-4.09-1.487-3.3.456.236,2.827Z" transform="translate(0 -285.035)" fill="#4a4b4f" />
          <path id="Tracé_4267" data-name="Tracé 4267" d="M48.116,371.419l-.768-4.519,4.474-1.943,7.818,1.311.226,4.112-4.248,1.672Z" transform="translate(-39.304 -302.956)" fill="#fff" />
          <path id="Tracé_4268" data-name="Tracé 4268" d="M53.127,370.071l-.043,0-7.5-.633a.509.509,0,0,1-.46-.422l-.768-4.519a.51.51,0,0,1,.3-.553L49.128,362a.51.51,0,0,1,.287-.035l7.818,1.311a.51.51,0,0,1,.425.475l.226,4.112a.509.509,0,0,1-.322.5l-4.248,1.672a.514.514,0,0,1-.187.035m-7.065-1.617,6.99.59,3.795-1.494-.183-3.338L49.4,362.994l-3.969,1.724Z" transform="translate(-36.813 -300.465)" fill="#4a4b4f" />
          <path id="Tracé_4269" data-name="Tracé 4269" d="M131.809,346.764l1.582-5.016,4.246-3.117,4.43,2.348,2.621,3.118-1.175,4.564L139.9,350.2l-6.553-1.22Z" transform="translate(-109.417 -281.102)" fill="#fff" />
          <path id="Tracé_4270" data-name="Tracé 4270" d="M137.408,348.218a.492.492,0,0,1-.093-.009l-6.553-1.22a.51.51,0,0,1-.326-.21l-1.536-2.214a.51.51,0,0,1-.067-.444l1.582-5.016a.51.51,0,0,1,.184-.258l4.246-3.117a.51.51,0,0,1,.54-.039l4.43,2.348a.511.511,0,0,1,.152.122l2.621,3.118a.51.51,0,0,1,.1.455l-1.175,4.564a.509.509,0,0,1-.294.342l-3.615,1.537a.511.511,0,0,1-.2.041m-6.253-2.193,6.2,1.154,3.242-1.378,1.047-4.067-2.388-2.841-4.06-2.152-3.854,2.829-1.456,4.619Z" transform="translate(-106.926 -278.612)" fill="#4a4b4f" />
          <path id="Tracé_4271" data-name="Tracé 4271" d="M82.483,330.748l1.9-5.468-2.44-1.762-3.389,1.039-1.627,2.983,1.22,3.389Z" transform="translate(-63.856 -268.556)" fill="#fff" />
          <path id="Tracé_4272" data-name="Tracé 4272" d="M75.654,328.948a.51.51,0,0,1-.479-.337l-1.22-3.389a.509.509,0,0,1,.032-.417l1.627-2.983a.509.509,0,0,1,.3-.243l3.389-1.039a.509.509,0,0,1,.448.074l2.44,1.763a.509.509,0,0,1,.183.58l-1.9,5.468a.509.509,0,0,1-.46.342l-4.338.181h-.021m-.663-3.856,1.016,2.822,3.618-.151,1.66-4.782-1.927-1.392-2.948.9Z" transform="translate(-61.366 -266.065)" fill="#4a4b4f" />
          <path id="Tracé_4273" data-name="Tracé 4273" d="M112.818,232.4l5.762.553,1.136-2.787-1.81-3.048-3.282-.877-3.005,1.986Z" transform="translate(-92.656 -187.809)" fill="#fff" />
          <path id="Tracé_4274" data-name="Tracé 4274" d="M116.09,230.976l-.049,0-5.762-.553a.51.51,0,0,1-.441-.366l-1.2-4.173a.51.51,0,0,1,.209-.566l3.005-1.986a.51.51,0,0,1,.412-.067l3.282.877a.509.509,0,0,1,.307.232l1.81,3.048a.51.51,0,0,1,.034.453l-1.136,2.788a.51.51,0,0,1-.472.317m-5.368-1.537,5.039.484.9-2.2-1.575-2.652-2.86-.764-2.5,1.653Z" transform="translate(-90.165 -185.318)" fill="#4a4b4f" />
          <path id="Tracé_4275" data-name="Tracé 4275" d="M94.769,354.124l2.4-3.118,1.175-3.073,5.016,2.26.5,5.061-3.208,2.666h-4.7Z" transform="translate(-78.669 -288.823)" fill="#95c515" />
          <path id="Tracé_4276" data-name="Tracé 4276" d="M98.067,355.076h-5.26l-1.364-4.407,2.51-3.269,1.341-3.508,5.778,2.6.552,5.624Zm-4.508-1.019H97.7l2.859-2.376-.442-4.5-4.255-1.916L94.852,347.9l-2.28,2.968Z" transform="translate(-75.908 -285.469)" fill="#4a4b4f" />
          <path id="Tracé_4277" data-name="Tracé 4277" d="M86.362,269.592l5.6.09,3.525-2.531,1.356-7.05-4.61-1.672-7,2.576-2.169,4.835Z" transform="translate(-68.952 -214.526)" fill="#95c515" />
          <path id="Tracé_4278" data-name="Tracé 4278" d="M89.2,267.543l-6-.1-3.664-4.167,2.391-5.33,7.381-2.714L94.5,257.12,93.026,264.8Zm-5.53-1.109,5.21.084L92.1,264.2l1.235-6.423-4.026-1.46-6.628,2.438L80.735,263.1Z" transform="translate(-66.025 -211.875)" fill="#4a4b4f" />
          <path id="Tracé_4279" data-name="Tracé 4279" d="M107.043,316.96l-1.13-4.338,1.491-1.808,4.881,1.356,3.751,5.016-2.576,2.937Z" transform="translate(-87.92 -258.01)" fill="#fff" />
          <path id="Tracé_4280" data-name="Tracé 4280" d="M110.97,318.142a.508.508,0,0,1-.225-.052l-6.417-3.163a.508.508,0,0,1-.268-.329l-1.13-4.338a.51.51,0,0,1,.1-.453L104.521,308a.51.51,0,0,1,.53-.167l4.881,1.356a.511.511,0,0,1,.272.186l3.751,5.016a.51.51,0,0,1-.025.641l-2.576,2.937a.509.509,0,0,1-.383.174m-5.983-4.027L110.845,317l2.046-2.333-3.4-4.546L105.1,308.9l-1.115,1.351Z" transform="translate(-85.43 -255.52)" fill="#4a4b4f" />
          <path id="Tracé_4281" data-name="Tracé 4281" d="M185.442,347.907l-1.627-4.61.316-3.344,3.615-1.989,4.293,1.2,3.48,3.028,1.22,2.3-1.808,3.751-4.88,1.672Z" transform="translate(-152.587 -280.549)" fill="#fff" />
          <path id="Tracé_4282" data-name="Tracé 4282" d="M187.561,347.938a.512.512,0,0,1-.2-.042l-4.61-2.011a.51.51,0,0,1-.277-.3l-1.627-4.61a.509.509,0,0,1-.027-.218l.316-3.344a.51.51,0,0,1,.262-.4l3.615-1.988a.51.51,0,0,1,.383-.044l4.293,1.2a.513.513,0,0,1,.2.106l3.48,3.028a.509.509,0,0,1,.116.146l1.22,2.3a.509.509,0,0,1,.009.46l-1.808,3.751a.509.509,0,0,1-.294.261l-4.881,1.672a.506.506,0,0,1-.165.028m-4.2-2.9,4.225,1.843,4.492-1.539,1.6-3.327-1.055-1.993-3.321-2.89-3.982-1.111-3.2,1.758-.28,2.964Z" transform="translate(-150.097 -278.059)" fill="#4a4b4f" />
        </g>
      </g>
    </svg>
  );
};

export default IconCollectesDI;
