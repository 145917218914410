import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { AppRouteType } from '../models';
import { Spinner } from '../components/Common';
import useAuthContext from '../store/auth-context';
import { hasPermission } from '../utils/permission';
import BasePage from '../components/Layout/BasePage/BasePage';

const Home = lazy(() => import('../pages/Home'));
const DemoProduct = lazy(() => import('../pages/DemoProduct'));
const DemoOrder = lazy(() => import('../pages/DemoOrder'));
const DemoOrderDetails = lazy(() => import('../pages/DemoOrderDetails'));
const Login = lazy(() => import('../pages/Login'));
const NotFound = lazy(() => import('../pages/404'));
const AgencyDetails = lazy(() => import('../pages/Agency'));
const CollecterMesDechets = lazy(() => import('../pages/CollecterMesDechets'));
const CommandesEnCours = lazy(() => import('../pages/CommandesEnCours'));
const CommandesRealisees = lazy(() => import('../pages/CommandesRealisees'));
const CommandesEnCoursDetails = lazy(() => import('../pages/CommandesEnCoursDetails'));;
const CommandesRealiseesDetails = lazy(() => import('../pages/CommandesRealiseesDetails'));;
const CommandesRealiseesFiltrer = lazy(() => import('../pages/CommandesRealiseesFiltrer'));;

const waitFor = (Tag: React.LazyExoticComponent<() => JSX.Element | null>) => (
  <Suspense fallback={<Spinner />}>
    <Tag />
  </Suspense>
);

export const routePage = {
  BASE: 0,
  GROUPE: 1,
  POINTS_DE_COLLECTS: 2,
};

export const routes: AppRouteType[] = [
  {
    name: 'Login',
    auth: false,
    path: 'login',
    component: Login,
  },
  {
    name: 'Demo products',
    auth: true,
    path: '/',
    component: DemoProduct,
  },
  {
    name: 'Demo orders',
    auth: true,
    path: '/orders',
    component: DemoOrder,
  },
  {
    name: 'Demo ordere details',
    auth: true,
    path: '/orders/:orderId',
    component: DemoOrderDetails
  },
  {
    name: '404',
    path: '404',
    component: NotFound,
    isPublic: true,
  },
  // {
  //   name: 'List of agencies',
  //   auth: true,
  //   path: '/',
  //   component: Home,
  // },
  {
    name: 'Collecter mes dechets',
    auth: true,
    path: '/collecter-mes-dechets',
    component: CollecterMesDechets,
    roles: routePage.POINTS_DE_COLLECTS,
  },
  {
    name: 'Commandes en cours',
    auth: true,
    path: '/commandes-en-cours',
    component: CommandesEnCours,
    roles: routePage.POINTS_DE_COLLECTS,
  },
  {
    name: 'Commandes en cours details',
    auth: true,
    path: '/commandes-en-cours/:orderId',
    component: CommandesEnCoursDetails,
    roles: routePage.POINTS_DE_COLLECTS,
  },
  {
    name: 'Commandes realisees',
    auth: true,
    path: '/commandes-realisees',
    component: CommandesRealisees,
    roles: routePage.POINTS_DE_COLLECTS,
  },
  {
    name: 'Commandes filtrer',
    auth: true,
    path: '/commandes-filtrer',
    component: CommandesRealiseesFiltrer,
    roles: routePage.POINTS_DE_COLLECTS,
  },
  {
    name: 'Commandes realisees details',
    auth: true,
    path: '/commandes-realisees/:orderId',
    component: CommandesRealiseesDetails,
    roles: routePage.POINTS_DE_COLLECTS,
  },
  {
    name: 'Agency',
    auth: true,
    path: 'agency/:agencyId',
    component: AgencyDetails,
    roles: routePage.GROUPE,
  },
  {
    name: 'Collecter mes dechets',
    auth: true,
    path: '/agency/:agencyId/collecter-mes-dechets',
    component: CollecterMesDechets,
    roles: routePage.GROUPE,
  },
  {
    name: 'Commandes en cours',
    auth: true,
    path: '/agency/:agencyId/commandes-en-cours',
    component: CommandesEnCours,
    roles: routePage.GROUPE,
  },
  {
    name: 'Commandes en cours details',
    auth: true,
    path: '/agency/:agencyId/commandes-en-cours/:orderId',
    component: CommandesEnCoursDetails,
    roles: routePage.GROUPE,
  },
  {
    name: 'Commandes realisees',
    auth: true,
    path: '/agency/:agencyId/commandes-realisees',
    component: CommandesRealisees,
    roles: routePage.GROUPE,
  },
  {
    name: 'Commandes filtrer',
    auth: true,
    path: '/agency/:agencyId/commandes-filtrer',
    component: CommandesRealiseesFiltrer,
    roles: routePage.GROUPE,
  },
  {
    name: 'Commandes realisees details',
    auth: true,
    path: '/agency/:agencyId/commandes-realisees/:orderId',
    component: CommandesRealiseesDetails,
    roles: routePage.GROUPE,
  },
];

export default function RoutesAppRoutes() {
  const { accessToken, profile } = useAuthContext();

  const publicRoutes = routes
    .filter((route) => !route.auth || route.isPublic)
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));

  // public routes
  if (!accessToken) return <Routes>{publicRoutes}</Routes>;

  // authenticated routes
  const authenticatedRoutes = routes
    .filter(
      (route) =>
        (route.auth && hasPermission(route.roles, profile)) || route.isPublic
    )
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));

  return (
    <BasePage>
      <Routes>{authenticatedRoutes}</Routes>
    </BasePage>
  );
}
