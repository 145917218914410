import request from './request';
import { Product } from '../models';

const productService = {
  // commandesEnCours: (pupsLocationId: string | number) =>
  //   request.get<{ products: Product[] }>(
  //     `/pups-locations/${pupsLocationId}/commandes-en-cours`
  //   ),
  // getProducts: (query: any) =>
  //   request.get<{ products: Product[] }>('/list-management-pups-locations', {
  //     params: query,
  //   }),
  getProducts: () =>
    request.get(
      '/products'
    ),
  findProduct: (id: number | string) =>
    request.get(
      `/products/${id}`
    ),
   
};

export default productService;
