import { PupsOrder } from '../models';
import request from './request';

const pupsOrderService = {
  getTimeServer: () => request.get('/get-time-create-pups-order'),
  createPupsOrder: (data: any) =>
    request.post<{ order_create: PupsOrder }>('/create-pups-order', data),
};

export default pupsOrderService;
