const IconSuivreMesCommandes = () => {
  return (
    <svg id="Suivre_mes_commandes" data-name="Suivre mes commandes" xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
      <rect id="Rectangle_1468" data-name="Rectangle 1468" width="72" height="72" fill="#fff" opacity="0" />
      <g id="Groupe_1423" data-name="Groupe 1423" transform="translate(12 12)">
        <path id="Tracé_4364" data-name="Tracé 4364" d="M91.479,423.161H61.4a2.946,2.946,0,0,1-2.946-2.947V386.172a2.946,2.946,0,0,1,2.946-2.946H91.479a2.946,2.946,0,0,1,2.946,2.946v34.042a2.946,2.946,0,0,1-2.946,2.947" transform="translate(-57.944 -375.667)" fill="#fff" />
        <path id="Tracé_4365" data-name="Tracé 4365" d="M88.986,421.555H58.9a3.472,3.472,0,0,1-3.452-3.484V383.71a3.472,3.472,0,0,1,3.452-3.484H88.986a3.472,3.472,0,0,1,3.452,3.484v34.361a3.472,3.472,0,0,1-3.452,3.484M58.9,381.247a2.455,2.455,0,0,0-2.441,2.463v34.361a2.455,2.455,0,0,0,2.441,2.464H88.986a2.455,2.455,0,0,0,2.441-2.464V383.71a2.455,2.455,0,0,0-2.441-2.463Z" transform="translate(-55.45 -373.555)" fill="#4a4b4f" />
        <path id="Tracé_4366" data-name="Tracé 4366" d="M133.627,346.8h-3.671a3.3,3.3,0,0,0-6.6,0h-3.671a.837.837,0,0,0-.837.837v5.749a.837.837,0,0,0,.837.837h13.942a.837.837,0,0,0,.837-.837v-5.749a.837.837,0,0,0-.837-.837" transform="translate(-108.262 -343.144)" fill="#fff" />
        <path id="Tracé_4367" data-name="Tracé 4367" d="M131.133,352.236H117.191a1.344,1.344,0,0,1-1.343-1.343v-5.749a1.344,1.344,0,0,1,1.343-1.342h3.218a3.81,3.81,0,0,1,7.506,0h3.218a1.344,1.344,0,0,1,1.342,1.342v5.749a1.344,1.344,0,0,1-1.342,1.343m-13.942-7.423a.332.332,0,0,0-.331.331v5.749a.332.332,0,0,0,.331.331h13.942a.332.332,0,0,0,.331-.331v-5.749a.332.332,0,0,0-.331-.331h-3.671a.506.506,0,0,1-.505-.482,2.8,2.8,0,0,0-5.591,0,.506.506,0,0,1-.5.482Z" transform="translate(-105.766 -340.649)" fill="#4a4b4f" />
        <path id="Tracé_4368" data-name="Tracé 4368" d="M96.868,443.808a.673.673,0,0,1-.477-.2l-2.842-2.841.953-.953,2.365,2.365,4.589-4.589.953.953-5.065,5.065a.673.673,0,0,1-.477.2" transform="translate(-87.128 -421.101)" fill="#95c515" />
        <path id="Tracé_4369" data-name="Tracé 4369" d="M96.468,432.6l.953.953-5.065,5.065a.674.674,0,0,1-.954,0l-2.842-2.841.953-.953,2.365,2.365Zm0-1.011a1.009,1.009,0,0,0-.715.3l-3.874,3.873-1.65-1.65a1.011,1.011,0,0,0-1.43,0l-.954.954a1.011,1.011,0,0,0,0,1.43l2.842,2.842a1.686,1.686,0,0,0,2.384,0l5.065-5.065a1.012,1.012,0,0,0,0-1.43l-.954-.953a1.008,1.008,0,0,0-.715-.3" transform="translate(-82.139 -416.114)" fill="#4a4b4f" />
        <line id="Ligne_124" data-name="Ligne 124" x2="12.849" transform="translate(17.863 20.501)" fill="#fff" />
        <rect id="Rectangle_1437" data-name="Rectangle 1437" width="12.849" height="1.011" transform="translate(17.863 19.995)" fill="#4a4b4f" />
        <path id="Tracé_4370" data-name="Tracé 4370" d="M96.868,497.316a.675.675,0,0,1-.477-.2l-2.842-2.842.953-.953,2.365,2.365,4.589-4.589.953.953-5.065,5.066a.674.674,0,0,1-.477.2" transform="translate(-87.128 -465.507)" fill="#95c515" />
        <path id="Tracé_4371" data-name="Tracé 4371" d="M96.468,486.111l.953.953-5.065,5.065a.674.674,0,0,1-.954,0l-2.842-2.842.953-.954L91.88,490.7Zm0-1.011a1.008,1.008,0,0,0-.715.3L91.88,489.27l-1.65-1.65a1.011,1.011,0,0,0-1.43,0l-.954.953a1.011,1.011,0,0,0,0,1.43l2.842,2.842a1.686,1.686,0,0,0,2.384,0l5.066-5.066a1.012,1.012,0,0,0,0-1.43l-.954-.953a1.008,1.008,0,0,0-.715-.3" transform="translate(-82.139 -460.516)" fill="#4a4b4f" />
        <line id="Ligne_125" data-name="Ligne 125" x2="12.849" transform="translate(17.863 29.593)" fill="#fff" />
        <rect id="Rectangle_1438" data-name="Rectangle 1438" width="12.849" height="1.011" transform="translate(17.863 29.088)" fill="#4a4b4f" />
        <path id="Tracé_4372" data-name="Tracé 4372" d="M96.868,550.824a.672.672,0,0,1-.477-.2l-2.842-2.841.953-.953,2.365,2.365,4.589-4.589.953.953-5.065,5.065a.672.672,0,0,1-.477.2" transform="translate(-87.128 -509.847)" fill="#95c515" />
        <path id="Tracé_4373" data-name="Tracé 4373" d="M96.468,539.619l.953.953-5.065,5.065a.674.674,0,0,1-.954,0L88.561,542.8l.953-.954,2.365,2.365Zm0-1.011a1.009,1.009,0,0,0-.715.3l-3.874,3.874-1.65-1.65a1.011,1.011,0,0,0-1.43,0l-.954.954a1.011,1.011,0,0,0,0,1.43l2.842,2.842a1.686,1.686,0,0,0,2.384,0l5.065-5.065a1.012,1.012,0,0,0,0-1.43l-.954-.953a1.008,1.008,0,0,0-.715-.3" transform="translate(-82.139 -504.859)" fill="#4a4b4f" />
        <line id="Ligne_126" data-name="Ligne 126" x2="12.849" transform="translate(17.863 38.758)" fill="#fff" />
        <rect id="Rectangle_1439" data-name="Rectangle 1439" width="12.849" height="1.011" transform="translate(17.863 38.181)" fill="#4a4b4f" />
        <path id="Union_7" data-name="Union 7" d="M25.7,28.438l-7.563-7.563a11.478,11.478,0,1,1,2.743-2.74L28.438,25.7A1.938,1.938,0,0,1,25.7,28.438ZM6.1,6.1a7.628,7.628,0,1,0,5.388-2.228A7.628,7.628,0,0,0,6.1,6.1Z" transform="translate(18.994 12.004)" fill="#e75012" stroke="#fff" strokeWidth="1" />
        <path id="Tracé_4376" data-name="Tracé 4376" d="M142.89,378.122v-.243a1.157,1.157,0,0,1,1.157-1.157h4.968a1.157,1.157,0,0,1,1.157,1.157v.243a1.157,1.157,0,0,1-1.157,1.157h-4.968a1.157,1.157,0,0,1-1.157-1.157" transform="translate(-128.261 -370.643)" fill="#95c515" />
        <path id="Tracé_4377" data-name="Tracé 4377" d="M146.522,377.289h-4.969a1.664,1.664,0,0,1-1.662-1.662v-.243a1.664,1.664,0,0,1,1.662-1.662h4.969a1.664,1.664,0,0,1,1.662,1.662v.243a1.664,1.664,0,0,1-1.662,1.662m-4.969-2.556a.652.652,0,0,0-.651.651v.243a.652.652,0,0,0,.651.651h4.969a.652.652,0,0,0,.651-.651v-.243a.652.652,0,0,0-.651-.651Z" transform="translate(-125.767 -368.147)" fill="#4a4b4f" />
      </g>
    </svg>

  );
};

export default IconSuivreMesCommandes;
