import {
  useState,
  useContext,
  useEffect,
  createContext,
  ReactNode,
} from 'react';

import { routes } from '../routes';
import { accountService } from '../services';
import { Spinner } from '../components/Common';
import { hasPermission } from '../utils/permission';
import { AppRouteType, DynamicObject, PupsAccount } from '../models';
import { setAxiosToken, removeAxiosToken } from '../services/request';
import {
  createSearchParams,
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

function useMergeState<Payload>(
  initialState: Payload
): [state: Payload, setMergedState: Function] {
  const [state, setState] = useState<Payload>(initialState);
  const setMergedState = (newState: DynamicObject<any>) =>
    setState((prevState) => ({ ...prevState, ...newState }));
  return [state, setMergedState];
}

interface AuthContextType {
  loading: boolean;
  accessToken: null | string | undefined;
  profile: null | PupsAccount;
  isAuth: boolean;
  rememberLogin: {
    remember: boolean;
    email: string;
    password: string;
  };
  onSignIn: (credentials: {
    email: string;
    password: string;
    // remember: boolean;
  }) => Promise<any>;
  onSignOut: () => void;
  // getProfile: () => void;
}

const AuthContext = createContext<AuthContextType>({
  loading: true,
  accessToken: null,
  profile: null,
  isAuth: false,
  rememberLogin: {
    remember: false,
    email: '',
    password: '',
  },
  onSignIn: () => Promise.resolve(),
  onSignOut: () => {},
  // getProfile: () => {},
});

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userData, setUserData] = useMergeState({
    loading: true,
    accessToken: sessionStorage.getItem('access_token'),
    isAuth: false,
    profile: null,
    rememberLogin: {
      remember: false,
      email: '',
      password: '',
    },
  });

  const currentPage = routes?.find((_: AppRouteType) =>
    matchPath(_.path, location.pathname) ? true : false
  );

  const onSignIn = async (credentials: {
    // remember: any;
    email: string;
    password: string;
    // remember: boolean;
  }) => {
    try {
      // setUserData({ loading: true });
      const result = await accountService.signIn(credentials);
      searchParams.delete('token');
      setSearchParams(searchParams);
      setAxiosToken(result.idToken);
      sessionStorage.setItem('access_token', result.idToken);
      sessionStorage.setItem('access_key_id', result.accessKeyId);
      sessionStorage.setItem('secret_access_key', result.secretAccessKey);
      sessionStorage.setItem('session_token', result.sessionToken);
      setUserData({
        accessToken: result.idToken
        // rememberLogin: credentials.remember
          // ? credentials
          // : {
          //   remember: false,
          //   email: null,
          //   password: null,
          // },
      });
    } catch (error: any) {
      setUserData({ loading: false });
      throw new Error(error);
    }
  };

  const getProfile = async () => {
    try {
      setUserData({
        loading: false,
        isAuth: true,
      });
      if (currentPage?.name === 'Login') {
        navigate('/', { replace: true });
      }
    } catch (error: any) {
        onSignOut();
    }

    // try {
    //   setUserData({ loading: true });
    //   const { user } = await accountService.profile();
    //   setUserData({
    //     loading: false,
    //     profile: user,
    //     isAuth: true,
    //   });
    //   if (currentPage?.name === 'Login') {
    //     navigate('/', { replace: true });
    //   }
    // } catch (error: any) {
    //     onSignOut();
    // }
  };

  useEffect(() => {
    if (!userData?.accessToken) {
      setUserData({ loading: false });
    }
    const token = searchParams.get('token');
    // router.asPath &&
    //   router.asPath.includes('token') &&
    //   router.query.token == undefined &&
    //   userData?.accessToken
    if (token && userData?.accessToken) {
      // only for the first load
      onSignOut();
    } else {
      if (!currentPage) {
        navigate('/', { replace: true });
      }
      if (!userData?.accessToken && currentPage?.auth) {
        setUserData({ loading: false });
        navigate('/login', { replace: true }); // If not authenticated, force log in
      }
      if (userData?.accessToken && !userData.profile) {
        setAxiosToken(userData?.accessToken);
        getProfile();
      }
    }
  }, [userData?.accessToken, currentPage]);

  useEffect(() => {
    if (userData?.accessToken) {
    // if (userData?.accessToken && userData?.profile) {
      // check permission
      // if (
      //   currentPage?.roles &&
      //   !hasPermission(currentPage.roles, userData.profile)
      // ) {
      //   navigate('/404', { replace: true });
      // } else 
      if (!currentPage?.auth && !currentPage?.isPublic) {
        // if already signed in, auto redirect to homepage if accesss to non-auth page
        navigate('/', { replace: true });
      }
    }
  }, [userData?.accessToken, userData?.profile, currentPage]);

  const onSignOut = async () => {
    try {
      if (userData?.profile) {
        await accountService.signOut();
      }
    } catch (error) {}
    setUserData({
      loading: false,
      accessToken: null,
      isAuth: false,
      profile: null,
    });
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('session_token');
    sessionStorage.removeItem('access_key_id');
    sessionStorage.removeItem('secret_access_key');
    removeAxiosToken();
    const tokenQuery = searchParams.get('token');
    navigate(
      {
        pathname: '/login',
        search: tokenQuery
          ? createSearchParams({
              token: tokenQuery,
            }).toString()
          : '',
      },
      { replace: true }
    );
  };

  return (
    <AuthContext.Provider
      value={{
        onSignIn,
        onSignOut,
        // getProfile,
        loading: userData.loading,
        accessToken: userData.accessToken,
        rememberLogin: userData.rememberLogin,
        profile: userData.profile,
        isAuth: userData.isAuth,
      }}
    >
      {/* {userData?.loading ||
      (currentPage?.roles &&
        !hasPermission(currentPage.roles, userData?.profile)) ? (
        // <Spinner />
        <></>
      ) : (
        children
      )} */}
      { children }
    </AuthContext.Provider>
  );
}

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
